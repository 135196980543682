.bg-img {
    background-image: url("../../assets/img/bg.jpg");
    position: relative;
    background-size: cover;
    background-position: center center;
}

.type-text {
    font-size: 54px;
}
