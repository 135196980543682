.btn-white {
    border: 1px solid #37b8df;
    background-color: #37b8df;
}
.btn-white:hover,
.btn-white.hover,
.btn-white:focus,
.btn-white.focus,
.btn-white:active,
.btn-white.active,
.open > .dropdown-toggle.btn-white{
    color: #fff;
    background-color: #036786;
    border-color: #036786;
}

.btn-outline-white {
    border: 2px solid #f5f5f5 !important;
    color: #fff !important;
}
.btn-outline-white:hover,
.btn-outline-white.hover,
.btn-outline-white:focus,
.btn-outline-white.focus,
.btn-outline-white:active,
.btn-outline-white.active,
.open > .dropdown-toggle.btn-white{
    color: #000 !important;
    background-color: #fff;
    border-color: #fff;
}

