.move-top {
    color: #fff;
    display: inline-block;
    height: 42px;
    width: 42px;
    font-size: 20px;
    border: 2px solid #6b6e6f;
    line-height: 38px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    text-align: center;
    position: fixed;
    right: 20px;
    bottom: 10px;
}
.move-top:hover {
    color: #37b8df;
    border-color: #37b8df;
}