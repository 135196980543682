.main-nav {
    background-color: #f7f7f7;
    color: #000 !important;
}

.dark .main-nav {
    background-color: transparent;
    color: #fff !important;
}

.logo {
    text-decoration: none;
    color: #000 !important;
}

.dark .logo {
    color: #fff !important;
}

.logo:hover,
.logo:focus {
     text-decoration: none;
     color: #a0a0ac !important;
}

.navbar-light .navbar-nav .show>.nav-link:hover,
.navbar-light .navbar-nav .show>.nav-link:focus,
.navbar-light .navbar-nav .show>.nav-link:active {
    color: #37b8df !important;
}

.navbar-light .navbar-nav .nav-link {
    color: #000 !important;
}

.dark .navbar-light .navbar-nav .nav-link {
    color: #fff !important;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:active,
.navbar-light .navbar-nav .nav-link.active {
    color: #37b8df !important;
    font-weight: bold;
}