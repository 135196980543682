.h-100vh {
  height: 100vh;
}

.img-thumbnail {
  background-color: #3b3e42;
  border: 1px solid #4d5052;
}

.bg-overlay {
  background-color: rgba(38, 40, 43, 0.72);
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.text-justify {
  text-align: justify;
}

.valign-middle {
  vertical-align: middle;
}

.grecaptcha-badge {
  display: none !important;
}
