.btn-round {
    border-radius: 30px !important;
}

.btn {
    padding: 15px 32px !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    transition: all 0.5s !important;
    letter-spacing: 0.6px;
    box-shadow: none !important;
    text-transform: uppercase;
    outline: none !important;
}