@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap);
.main-nav {
    background-color: #f7f7f7;
    color: #000 !important;
}

.dark .main-nav {
    background-color: transparent;
    color: #fff !important;
}

.logo {
    text-decoration: none;
    color: #000 !important;
}

.dark .logo {
    color: #fff !important;
}

.logo:hover,
.logo:focus {
     text-decoration: none;
     color: #a0a0ac !important;
}

.navbar-light .navbar-nav .show>.nav-link:hover,
.navbar-light .navbar-nav .show>.nav-link:focus,
.navbar-light .navbar-nav .show>.nav-link:active {
    color: #37b8df !important;
}

.navbar-light .navbar-nav .nav-link {
    color: #000 !important;
}

.dark .navbar-light .navbar-nav .nav-link {
    color: #fff !important;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:active,
.navbar-light .navbar-nav .nav-link.active {
    color: #37b8df !important;
    font-weight: bold;
}
.btn-round {
    border-radius: 30px !important;
}

.btn {
    padding: 15px 32px !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    transition: all 0.5s !important;
    letter-spacing: 0.6px;
    box-shadow: none !important;
    text-transform: uppercase;
    outline: none !important;
}
.btn-white {
    border: 1px solid #37b8df;
    background-color: #37b8df;
}
.btn-white:hover,
.btn-white.hover,
.btn-white:focus,
.btn-white.focus,
.btn-white:active,
.btn-white.active,
.open > .dropdown-toggle.btn-white{
    color: #fff;
    background-color: #036786;
    border-color: #036786;
}

.btn-outline-white {
    border: 2px solid #f5f5f5 !important;
    color: #fff !important;
}
.btn-outline-white:hover,
.btn-outline-white.hover,
.btn-outline-white:focus,
.btn-outline-white.focus,
.btn-outline-white:active,
.btn-outline-white.active,
.open > .dropdown-toggle.btn-white{
    color: #000 !important;
    background-color: #fff;
    border-color: #fff;
}


.bg-img {
    background-image: url(/static/media/bg.103a0765.jpg);
    position: relative;
    background-size: cover;
    background-position: center center;
}

.type-text {
    font-size: 54px;
}

.social-icon {
    color: #6b6e6f;
    display: inline-block;
    height: 42px;
    width: 42px;
    font-size: 20px;
    border-radius: 50%;
    border: 2px solid #6b6e6f;
    line-height: 38px;
    transition: all 0.5s;
    text-align: center;
}

.social-icon:hover {
    color: #37b8df;
    border-color: #37b8df;
}

.form-contact .form-control {
    box-shadow: none !important;
    height: 50px;
    border: 2px solid #3b3e42;
    background-color: transparent;
    color: #fbfbfb;
}

.form-contact textarea.form-control {
    height: auto;
}

.form-contact .form-control:focus {
    color: #fbfbfb;
    border-color: #37b8df;
    background-color: transparent;
}

.btn-blue {
    color: #fff !important;
    border:1px solid #37b8df !important;
    background-color: #37b8df !important;
}

.btn-blue:hover,
.btn-blue.hover,
.btn-blue:focus,
.btn-blue.focus,
.btn-blue:active,
.btn-blue.active {
    color: #fff !important;
    background-color: #036786 !important;
    border-color: #036786 !important;
}
.move-top {
    color: #fff;
    display: inline-block;
    height: 42px;
    width: 42px;
    font-size: 20px;
    border: 2px solid #6b6e6f;
    line-height: 38px;
    transition: all 0.5s;
    text-align: center;
    position: fixed;
    right: 20px;
    bottom: 10px;
}
.move-top:hover {
    color: #37b8df;
    border-color: #37b8df;
}
.h-100vh {
  height: 100vh;
}

.img-thumbnail {
  background-color: #3b3e42;
  border: 1px solid #4d5052;
}

.bg-overlay {
  background-color: rgba(38, 40, 43, 0.72);
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.text-justify {
  text-align: justify;
}

.valign-middle {
  vertical-align: middle;
}

.grecaptcha-badge {
  display: none !important;
}

html,
body {
    height: 100%;
}

body {
    font-family: "Nunito", Arial, Helvetica, sans-serif;
    background-color: #2e3135;
    color: #fff;
    position: relative;
}

a {
    text-decoration: none;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    background: #37b8df;
}

::-webkit-scrollbar-thumb:hover {
    background: #036786;
}

.font-oswald {
    font-family: "Oswald", Arial, Helvetica, sans-serif;
}

.font-nunito {
    font-family: "Nunito", Arial, Helvetica, sans-serif;
}

.text-light-blue {
    color: #37b8df !important;
}

.bg-dark-grey {
    background-color: #2e3135;
}

.bg-darker-grey {
    background-color: #26282b;
}

.page-section {
    padding-top: 100px;
    padding-bottom: 100px;
}
