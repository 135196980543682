.social-icon {
    color: #6b6e6f;
    display: inline-block;
    height: 42px;
    width: 42px;
    font-size: 20px;
    border-radius: 50%;
    border: 2px solid #6b6e6f;
    line-height: 38px;
    transition: all 0.5s;
    text-align: center;
}

.social-icon:hover {
    color: #37b8df;
    border-color: #37b8df;
}