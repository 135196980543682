@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

html,
body {
    height: 100%;
}

body {
    font-family: "Nunito", Arial, Helvetica, sans-serif;
    background-color: #2e3135;
    color: #fff;
    position: relative;
}

a {
    text-decoration: none;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    background: #37b8df;
}

::-webkit-scrollbar-thumb:hover {
    background: #036786;
}

.font-oswald {
    font-family: "Oswald", Arial, Helvetica, sans-serif;
}

.font-nunito {
    font-family: "Nunito", Arial, Helvetica, sans-serif;
}

.text-light-blue {
    color: #37b8df !important;
}

.bg-dark-grey {
    background-color: #2e3135;
}

.bg-darker-grey {
    background-color: #26282b;
}

.page-section {
    padding-top: 100px;
    padding-bottom: 100px;
}