
.form-contact .form-control {
    box-shadow: none !important;
    height: 50px;
    border: 2px solid #3b3e42;
    background-color: transparent;
    color: #fbfbfb;
}

.form-contact textarea.form-control {
    height: auto;
}

.form-contact .form-control:focus {
    color: #fbfbfb;
    border-color: #37b8df;
    background-color: transparent;
}

.btn-blue {
    color: #fff !important;
    border:1px solid #37b8df !important;
    background-color: #37b8df !important;
}

.btn-blue:hover,
.btn-blue.hover,
.btn-blue:focus,
.btn-blue.focus,
.btn-blue:active,
.btn-blue.active {
    color: #fff !important;
    background-color: #036786 !important;
    border-color: #036786 !important;
}